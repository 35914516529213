const {
  faRocket,
  faSliders,
  faCoins,
} = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");

const Pages = {
  operationalPages: [
    {
      parent: "Operasyonel İşlemler",
      icon: <FontAwesomeIcon icon={faRocket} />,
      children: [
        {
          text: "Başarılı Yatırım İşlemleri",
          to: "/success-deposit",
          permissionName: "successDeposit",
        },
        {
          text: "Reddedilen Yatırım İşlemleri",
          to: "/rejection-deposit",
          permissionName: "rejectionDeposit",
        },
        {
          text: "Bekleyen Yatırım İşlemleri",
          to: "/waiting-deposit",
          permissionName: "waitingDeposit",
        },
        {
          text: "Başarılı Çekim İşlemleri",
          to: "/success-withdraw",
          permissionName: "successWithdraw",
        },
        {
          text: "Reddedilen Çekim İşlemleri",
          to: "/rejection-withdraw",
          permissionName: "rejectionWithdraw",
        },
        {
          text: "Bekleyen Çekim İşlemleri",
          to: "/waiting-withdraw",
          permissionName: "waitingWithdraw",
        },
      ],
    },
  ],
  definitionPages: [
    {
      parent: "Tanımlamalar",
      icon: <FontAwesomeIcon icon={faSliders} />,
      children: [
        {
          text: "Oyuncular",
          to: "/players",
          permissionName: "players",
        },
        {
          text: "Kullanıcılar",
          to: "/users",
          permissionName: "users",
        },
        {
          text: "Kullanıcı Oluştur",
          to: "/create-user",
          permissionName: "createUser",
        },
        {
          text:
            process.env.REACT_APP_PAPARA_ENABLED === "true"
              ? "Papara Hesapları"
              : "Banka Hesapları",
          to: "/bank-accounts",
          permissionName: "bankAccounts",
        },
      ],
    },
  ],
  financialPages: [
    {
      parent: "Finansal İşlemler",
      icon: <FontAwesomeIcon icon={faCoins} />,
      children: [
        {
          text: "Mutabakat Ekranı",
          to: "/consensus",
          permissionName: "consensus",
        },
        {
          text: "Saha Ayarları",
          to: "/pools",
          permissionName: "pools",
        },
        {
          text: "Loglar",
          to: "/logs",
          permissionName: "logs",
        },
        {
          text: "Web Siteleri",
          to: "/websites",
          permissionName: "websites",
        },

        {
          text: "Kasa ve Teslimat",
          to: "/cash-delivery",
          permissionName: "casDelivery",
        },
      ],
    },
  ],
};

export default Pages;
